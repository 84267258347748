import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
const style = {
  table: {
    borderCollapse: 'collapse'
  },
  tableCell: {
    border: '1px solid gray',
    margin: 0,
    padding: '5px 10px',
    width: 'max-content',
    minWidth: '150px',
    textAlign: 'center'
  },
  form: {
    container: {
      padding: '20px',
      border: '1px solid #F0F8FF',
      borderRadius: '15px',
      width: 'max-content',
      marginBottom: '40px'
    },
    inputs: {
      marginBottom: '5px'
    },
    submitBtn: {
      marginTop: '10px',
      padding: '10px 15px',
      border: 'none',
      backgroundColor: 'lightseagreen',
      fontSize: '14px',
      borderRadius: '5px'
    }
  }
}

function PhoneBookForm({ addEntryToPhoneBook }) {
  const [inputData, setInputData] = useState({ userFirstname: '', userLastname: '', userPhone: '' })

  const handleChange = (inputValue, inputName) => {
    setInputData(curr => {
      return { ...curr, [inputName]: inputValue }
    })
  }
  const handleSave = (e) => {
    setInputData({ userFirstname: '', userLastname: '', userPhone: '' })
    e.preventDefault()
    addEntryToPhoneBook(inputData);
  }
  return (
    <form onSubmit={handleSave} style={style.form.container}>
      <label>First name:</label>
      <br />
      <input
        style={style.form.inputs}
        className='userFirstname'
        name='userFirstname'
        value={inputData.userFirstname}
        type='text'
        onChange={(e) => handleChange(e.target.value, e.target.name)}
      />
      <br />
      <label>Last name:</label>
      <br />
      <input
        style={style.form.inputs}
        className='userLastname'
        name='userLastname'
        value={inputData.userLastname}
        type='text'
        onChange={(e) => handleChange(e.target.value, e.target.name)}
      />
      <br />
      <label>Phone:</label>
      <br />
      <input
        style={style.form.inputs}
        className='userPhone'
        name='userPhone'
        value={inputData.userPhone}
        type='number'
        onChange={(e) => handleChange(e.target.value, e.target.name)}
      />
      <br />
      <input
        style={style.form.submitBtn}
        className='submitButton'
        type='submit'
        value='Add User'
      />
    </form>
  )
}

function InformationTable(props) {

  let { data } = props;
  const [upData, setUpData] = useState([]);
  const [sortdata, setSortData] = useState(false)
   useEffect(()=>{
    setUpData(data)
   },[data])
    const handleSort = () =>{
     let udata = upData.sort((a, b)=> {
        const nameA = a.userFirstname.toLowerCase(); 
        const nameB = b.userFirstname.toLowerCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }else if (nameA > nameB) {
          return 1;
        }
      });
      setUpData(udata);
      setSortData(!sortdata)
    }
  return (
    <table style={style.table} className='informationTable'>
      <thead>
        <tr>
          <th style={style.tableCell} onClick={handleSort}>First name</th>
          <th style={style.tableCell}>Last name</th>
          <th style={style.tableCell}>Phone</th>
        </tr>
      </thead>
      <tbody>
        {upData ? upData.map((item, index) => {
          return <tr key={`${item.userFirstname}${index}${sortdata}`} style={{color:'#fff'}}>
            <td style={style.tableCell}>{item.userFirstname}</td>
            <td style={style.tableCell}>{item.userLastname}</td>
            <td style={style.tableCell}>{item.userPhone}</td>
          </tr>
        }) : <tr><td>No data list</td></tr>}
      </tbody>
    </table>
  );
}

function Application(props) {
  const [formData, setFormData] = useState([{ userFirstname: 'Rajesh', userLastname: 'mishra', userPhone: '999999999' }])
  const addEntryToPhoneBook = (e) => {
    if(e.userFirstname !=='' || e.userLastname !==''){
      setFormData((prev) => {
        return [...prev, e]
      })
    }
    
  }
  return (
    <section>
      <PhoneBookForm addEntryToPhoneBook={addEntryToPhoneBook} />
      <InformationTable data={formData} />
    </section>
  );
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
